// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageSlider } from "../components/imageSlider"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { FeaturesList } from "../components/featuresList"
import { SpecificationsTable } from "../components/specificationsTable"

// COMPONENT FUNCTION
const AlcarEnvironmentalPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      crystalRiver: file(relativePath: { eq: "crystal-river.jpg" }) {
        ...BsmFluidImage
      }
      duxbury: file(relativePath: { eq: "duxbury.jpg" }) {
        ...BsmFluidImage
      }
      fairhaven: file(relativePath: { eq: "fairhaven.jpg" }) {
        ...BsmFluidImage
      }
      goingCoastal: file(relativePath: { eq: "going-coastal.jpg" }) {
        ...BsmFluidImage
      }
      salem: file(relativePath: { eq: "salem.jpg" }) {
        ...BsmFluidImage
      }
      townOfBourne: file(relativePath: { eq: "town-of-bourne.jpg" }) {
        ...BsmFluidImage
      }
      alcarEnvironmentalPumpoutBoat2: file(
        relativePath: { eq: "alcar-environmental-pumpout-boat-02.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarEnvironmentalPumpoutBoat3: file(
        relativePath: { eq: "alcar-environmental-pumpout-boat-03.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  const imageSteps = [
    {
      label: "Crystal River",
      imgPath: data.crystalRiver.childImageSharp.fluid.src,
    },
    {
      label: "Duxbury",
      imgPath: data.duxbury.childImageSharp.fluid.src,
    },
    {
      label: "Fairhaven",
      imgPath: data.fairhaven.childImageSharp.fluid.src,
    },
    {
      label: "Going Coastal",
      imgPath: data.goingCoastal.childImageSharp.fluid.src,
    },
    {
      label: "Salem",
      imgPath: data.salem.childImageSharp.fluid.src,
    },
    {
      label: "Town of Bourne",
      imgPath: data.townOfBourne.childImageSharp.fluid.src,
    },
  ]

  // FEATURES
  const features = [
    "Non-skid flooring",
    "Fiberglass pumpout cover",
    "Stainless steel cleats",
    "Radio boxes",
    "Fiberglass T-tops",
    "Dewatering attachements",
    "Edson diaphragm pump",
    "Self bailing cockpit",
    "Soft riding modified V-hull",
    "Maintenance-free molded fiberglass hull and deck",
    "One piece integral stringer grid",
    "User-friendly mistake-proof hose connections",
    "Fully foamed hulled and deck",
    "Exceeds USCG standards for upright level foam floatation",
  ]

  // SPECIFICATIONS
  const specifications = {
    Length: ["19' - 23'"],
    Beam: ["8' - 8'6\""],
    Draft: ['10" - 14"'],
    Weight: ["2000 - 2800 lbs"],
    "Max HP": ["130 - 225"],
    "Hull Type": ["Modified V"],
    "Cargo Capacity": ["200 - 600 gal"],
    "Fuel Tank": ["24 - 57 gal"],
  }

  // COMPONENT
  return (
    <Layout title="Alcar Environmental">
      <PageTitle title="ALCAR ENVIRONMENTAL PUMPOUT BOAT" />

      <Typography paragraph>
        The Alcar Environmental pumpout boat is the perfect answer for a high
        quality, low maintenance, and multi-purpose utility craft at a
        cost-effective price. It is designed to be user-friendly with a smooth
        riding V-hull and a fully self-bailing cockpit large enough for the most
        demanding jobs yet easily managed by a single operator. In addition to
        being an excellent pumpout boat the Alcar Environmental makes a great
        work boat, harbormaster boat, rescue craft, or police boat.
      </Typography>

      <ImageSlider steps={imageSteps} />

      <FeaturesList features={features} />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarEnvironmentalPumpoutBoat2.childImageSharp.fluid}
          caption="Pumpout Boat Deck"
          displayCaption
        />
      </ImageBlock>

      <SpecificationsTable specifications={specifications} />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarEnvironmentalPumpoutBoat3.childImageSharp.fluid}
          caption="Above-deck Edson gas-powered pumpout pump"
          displayCaption
        />
      </ImageBlock>
    </Layout>
  )
}

export default AlcarEnvironmentalPage
